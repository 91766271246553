import React from 'react';
import { useRouter } from 'next/router';
import InstagramIcon from '../icons/InstagramIcon';
import LinkedInIcon from '../icons/LinkedInIcon';
import InfoModal from '../modals/InfoModal';

interface FooterLinkProps {
  title: string;
  href: string;
  as?: string;
}

function FooterLink({ title, href, as }: FooterLinkProps) {
  return (
    <a
      href={href}
      className="tracking-wide text-white hover:text-secondary-500 text-sm block"
    >
      {title}
    </a>
  );
}

export default function Footer() {
  const [isOpen, setOpen] = React.useState(false);
  const router = useRouter();

  React.useEffect(() => {
    if (router.query && router.query.modal) {
      // https://www.bloopex.com/?modal=true
      setOpen(router.query.modal === 'true');
    }
  }, [router]);

  return (
    <>
      <footer className="w-full border-t-2 border-b-2 border-secondary-500 text-white mt-18">
        <div className="grid grid-cols-2 md:grid-cols-4 px-4 lg:px-8 py-6 md:py-4 gap-10 md:gap-0">
          {/* Menu */}
          <div>
            <h1 className="text-lg lg:text-xl uppercase">Menu</h1>
            <ul className="flex flex-col mt-2">
              <FooterLink title="Home" href="#home" />
              <FooterLink title="Bloopex" href="#about-us" />
              <FooterLink title="¿Cómo funciona?" href="#how-it-works" />
              <FooterLink title="Contacto" href="#contact" />
            </ul>
          </div>

          {/* Contact */}
          <div>
            <h1 className="text-lg lg:text-xl uppercase">Contacto</h1>
            <ul className="text-sm mt-2 tracking-wide">
              <li>info@probloop.com</li>
              <li>+58 414 330 43 44</li>
              <li>+58 212 286 25 82</li>
            </ul>
          </div>

          {/* Address */}
          <div>
            <h1 className="text-lg lg:text-xl uppercase">Dirección</h1>
            <div className="tracking-wide text-sm">
              <p className="w-full sm:w-64 md:w-auto mt-2">
                1era Avenida de Santa Eduvigis, Edificio Romar, P.B. Oficina
                Nro. 2 y 4, Urbanización Santa Eduvigis, Caracas.
              </p>
              <p>1071, Venezuela</p>
            </div>
          </div>

          {/* Work */}
          <div className="flex flex-col md:items-center">
            <h1 className="text-lg lg:text-xl uppercase">
              Trabaja con nosotros
            </h1>
            <button
              type="button"
              className="bg-secondary-500 rounded-full text-primary-500 py-0.5 mt-2 w-32 hover:bg-secondary-400"
              onClick={(e) => {
                e.preventDefault();
                setOpen(true);
              }}
            >
              <h6>Solicitud</h6>
            </button>
            <p className="mt-2 text-sm">
              Desarrollado por{' '}
              <a
                href="http://avilatek.dev"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-green-700"
              >
                Avila Tek
                <span className="ml-1">
                  <svg
                    className="h-6 w-6 xl:h-8 xl:w-8 inline pb-1"
                    viewBox="0, 0, 400,229.36096718480138"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M285.838 37.694 C 283.225 38.298,281.341 39.149,278.515 40.999 C 273.392 44.353,273.873 43.532,255.243 80.688 C 246.043 99.039,238.426 114.108,238.318 114.176 C 238.209 114.244,232.940 103.932,226.608 91.260 C 215.050 68.129,213.601 65.425,211.612 63.272 C 202.368 53.263,184.407 54.366,177.088 65.391 C 176.408 66.417,169.533 79.887,161.811 95.325 L 147.772 123.393 137.997 103.869 C 126.656 81.220,125.237 79.087,119.765 76.478 L 118.480 75.865 118.480 66.294 C 118.480 57.885,118.414 56.698,117.934 56.514 C 117.633 56.398,115.457 56.304,113.098 56.304 L 108.808 56.304 108.808 65.112 L 108.808 73.921 107.309 73.921 C 101.486 73.921,93.854 78.391,90.406 83.823 C 89.281 85.596,31.779 200.562,31.779 201.039 C 31.779 201.265,92.039 201.382,209.154 201.382 C 326.951 201.382,386.528 201.266,386.528 201.037 C 386.528 200.455,310.318 48.238,309.188 46.564 C 304.465 39.566,294.355 35.726,285.838 37.694 "
                      stroke="none"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </a>
            </p>
          </div>
        </div>

        <hr className="border border-secondary-500" />

        <div className="px-4 lg:px-8 flex items-center py-3">
          <h2 className="uppercase text-lg mr-2">Síguenos</h2>
          <a
            href="https://www.instagram.com/bloopex_ve/"
            target="_blank"
            className="hover:text-secondary-500"
            rel="noreferrer"
          >
            <InstagramIcon className="h-5 w-5 mx-2" />
          </a>
          <a
            href="https://ve.linkedin.com/company/bloopex"
            target="_blank"
            className="hover:text-secondary-500"
            rel="noreferrer"
          >
            <LinkedInIcon className="h-5 w-5 mx-2" />
          </a>
        </div>
      </footer>
      <InfoModal isOpen={isOpen} setOpen={setOpen} />
    </>
  );
}
