import PlayIcon from '../icons/PlayIcon';

const items = [
  {
    title: 'step1',
    img: '/step1.png',
    text: (
      <p className="leading-tight">
        Envíanos tu solicitud respondiendo el cuestionario de nuestra solicitud
        digital
      </p>
    ),
  },
  {
    title: 'step2',
    img: '/step2.png',
    text: (
      <p className="leading-tight">
        Recibe múltiples propuestas por parte de nuestros aliados estratégicos.
      </p>
    ),
  },
  {
    title: 'step3',
    img: '/step3.png',
    text: (
      <p className="leading-tight">
        Compara y elige la que más se ajuste a tus requerimientos.
      </p>
    ),
  },
  {
    title: 'step4',
    img: '/step4.png',
    text: (
      <p className="leading-tight">
        Solicita los ajustes que desees, cuantas veces sea necesario.
      </p>
    ),
  },
  {
    title: 'step5',
    img: '/step5.png',
    text: (
      <p className="leading-tight">
        Recibe tus productos bajo la supervisión de Bloopex
      </p>
    ),
  },
];

export default function HowItWorks() {
  return (
    <section className="anchor w-full min-h-screen text-white mt-6 flex flex-col justify-center items-center sm:space-y-20">
      {/* Section title */}
      <div id="how-it-works" className="anchor w-full text-center">
        <h1 className="uppercase text-3xl md:text-4xl lg:text-5xl mt-8">
          ¿CÓMO FUNCIONA?
        </h1>
      </div>
      {/* Content */}
      <div className="w-full flex md:flex-row flex-col justify-center items-center md:mt-16 mt-6">
        {items.map((item) => (
          <div
            key={item.img}
            className="flex flex-col justify-center items-center text-center md:w-1/5 w-full my-5 sm:my-0"
          >
            <div className="h-28 md:h-30 lg:h-32">
              <img
                src={item.img}
                alt={item.title}
                className="mx-auto h-26 xl:h-28"
              />
            </div>
            <div className="mt-4 w-5/6 lg:w-52 xl:w-56 text-sm md:text-base">
              {item.text}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
