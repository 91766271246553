import React from 'react';
import { Router } from 'next/router';

interface NavItemProps {
  title: string;
  href: string;
  as?: string;
}

function NavItem({ title, href, as }: NavItemProps) {
  return (
    <a
      href={href}
      className="tracking-wide uppercase mx-3 text-white hover:text-secondary-500 text-lg my-2 md:my-0 bebas block py-1 relative"
    >
      {title}
    </a>
  );
}

export default function Nav() {
  const [isOpen, setOpen] = React.useState(false);

  const toggleNav = () => {
    setOpen((prev) => !prev);
  };

  Router.events.on('hashChangeStart', () => {
    setOpen(false);
  });

  return (
    <nav
      className={`fixed top-0 left-0 z-10 w-full md:bg-primary-600 text-white px-10 pt-2 flex flex-wrap justify-between md:h-20 ${
        isOpen ? 'bg-primary-600 h-screen' : 'bg-primary-600 h-20'
      }`}
      role="navigation"
    >
      <img
        src="/bloopexlogo.png"
        alt="dark bloop logo"
        className={isOpen ? 'h-20' : 'h-full'}
      />

      <div className="ml-auto md:hidden pt-5">
        <button
          className="text-white hover:text-secondary-500 focus:ring-0 focus:outline-none"
          type="button"
          onClick={toggleNav}
        >
          <svg
            className="h-5 w-5"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
          >
            {isOpen ? (
              <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
            ) : (
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            )}
          </svg>
        </button>
      </div>

      <div
        className={`w-full h-full md:h-auto md:w-auto md:flex items-center ${
          isOpen ? 'my-12 md:my-0' : 'hidden'
        }`}
      >
        <NavItem title="HOME" href="#home" />
        <NavItem title="CONÓCENOS" href="#about-us" />
        <NavItem title="¿CÓMO FUNCIONA?" href="#how-it-works" />
        <NavItem title="CONTACTO" href="#contact" />
      </div>
    </nav>
  );
}
