/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { useRouter } from 'next/router';
import InstagramIcon from '../icons/InstagramIcon';
import LinkedInIcon from '../icons/LinkedInIcon';

export default function Home() {
  const router = useRouter();
  return (
    <section id="home" className="w-full text-white">
      <div className="w-full min-h-screen flex flex-col justify-around">
        {/* Home section content */}
        <div className="w-full flex flex-col md:flex-row justify-between pt-26 md:pt-20">
          <div className="w-full md:w-1/2 xl:w-106 flex flex-col justify-center pr-4">
            <h1 className="uppercase text-3xl md:text-4xl lg:text-5xl leading-none text-secondary-500">
              <span className="text-white">
                Optimiza las compras de tu empresa con
              </span>{' '}
              Bloopex
            </h1>
            <p className="mt-10 leading-snug text-justify text-sm md:text-base">
              En Bloopex simplificamos las compras corporativas gracias un
              sistema que elimina el proceso de investigación de proveedores,
              facilita el proceso licitación y se ajusta a las políticas de tu
              organización . Simplifica las compras de tu empresa respondiendo
              el cuestionario de nuestra solicitud digital.
            </p>
            <button
              type="button"
              className="bg-secondary-500 rounded-full text-primary-500 py-1 mt-10 w-40 hover:bg-secondary-400"
              onClick={(e) => {
                e.preventDefault();
                router.push('/request');
              }}
            >
              <h6>Solicitud digital</h6>
            </button>
          </div>
          <div className="w-full md:w-1/2 h-full">
            {/* <LaptopImg className="w-9/12 md:w-10/12 xl:w-106 my-14 md:my-0 mx-auto md:ml-auto" /> */}
            <img
              src="/home.png"
              alt="dark bloop logo"
              className="mx-auto w-full"
            />
          </div>
        </div>
        {/* RRSS */}
        <div className="flex items-center">
          <a
            href="https://www.instagram.com/bloopex_ve/"
            target="_blank"
            className="hover:text-secondary-500"
          >
            <InstagramIcon className="h-5 w-5 mx-2" />
          </a>
          <a
            href="https://www.linkedin.com/company/bloopex/"
            target="_blank"
            className="hover:text-secondary-500"
          >
            <LinkedInIcon className="h-5 w-5 mx-2" />
          </a>
          {/* <a href="/" target="_blank" className="hover:text-secondary-500">
            <TwitterIcon className="h-5 w-5 mx-2" />
          </a> */}
          <hr className="border border-secondary-500 w-48 ml-4" />
        </div>
      </div>
    </section>
  );
}
