import React from 'react';
import NProgress from 'nprogress';
import axios from 'axios';
import { validateString, validatePhone } from 'avilatek-utils';
import { EMAIL_TEMPLATES_NAMES } from '../../config';
import WarningModal from '../modals/WarningModal';

export default function Contact() {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [subject, setSubject] = React.useState('');
  const [comments, setComments] = React.useState('');
  const [disabled, setDisabled] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [title, setTitle] = React.useState(null);

  async function sendEmails() {
    const resData = await axios.post('api/send-email', {
      templates: EMAIL_TEMPLATES_NAMES.CONTACT_DATA,
      from: 'Bloopex',
      firstName,
      lastName,
      email,
      phone,
      subject,
      message: comments,
    });
    const resClient = await axios.post('api/send-email', {
      template: EMAIL_TEMPLATES_NAMES.CONTACT_FORM_CLIENT,
      from: 'Bloopex',
      email,
      name: firstName,
    });
    await Promise.allSettled([resData, resClient]);
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      if (disabled) return;
      if (!validateString(firstName)) {
        setMessage('El campo NOMBRE es inválido');
        setTitle(null);
        setOpenWarning(true);
        return;
      }
      if (!validateString(lastName)) {
        setMessage('El campo APELLIDO es inválido');
        setTitle(null);
        setOpenWarning(true);
        return;
      }
      if (!validateString(email)) {
        setMessage('El campo CORREO es inválido');
        setTitle(null);
        setOpenWarning(true);
        return;
      }
      if (!validatePhone(phone)) {
        setMessage('El campo TÉLEFONO es inválido');
        setTitle(null);
        setOpenWarning(true);
        return;
      }
      if (!validateString(subject)) {
        setMessage('El campo ASUNTO es inválido');
        setTitle(null);
        setOpenWarning(true);
        return;
      }
      if (!validateString(comments)) {
        setMessage('El campo MENSAJE es inválido');
        setTitle(null);
        setOpenWarning(true);
        return;
      }
      setDisabled(true);
      NProgress.start();
      await sendEmails();
    } catch (err) {
      console.log(err);
    } finally {
      setDisabled(false);
      NProgress.done();
    }
  };

  return (
    <>
      <section className="w-full min-h-screen text-white mt-12  mb-16">
        {/* Section title */}
        <div id="contact" className="anchor w-full text-center">
          <h2 className="uppercase text-3xl md:text-4xl lg:text-5xl">
            Contacto
          </h2>
        </div>
        {/* Content */}
        <div className="w-full flex flex-col md:flex-row justify-between mt-20 md:mt-32">
          <div className="w-full md:w-1/2 lg:w-106 max-w-lg pr-2 mx-auto">
            <form method="POST" onSubmit={onSubmit}>
              {/* First row */}
              <div className="w-full flex flex-wrap justify-between">
                {/* Name */}
                <div className="w-full sm:w-60 md:w-1/2 md:pr-2">
                  <div className="w-full border-b-2 border-secondary-500 py-1 px-2 mb-10">
                    <input
                      name="name"
                      type="text"
                      placeholder="Nombre"
                      value={firstName}
                      onChange={(e) => {
                        e.preventDefault();
                        setFirstName(e.target.value);
                      }}
                      className="appearance-none bg-transparent border-none w-full text-white mr-3 leading-tight focus:ring-0 focus:outline-none text-sm"
                      required
                      maxLength={100}
                    />
                  </div>
                </div>
                {/* Last name */}
                <div className="w-full sm:w-60 md:w-1/2 md:pl-2">
                  <div className="w-full border-b-2 border-secondary-500 py-1 px-2 mb-10">
                    <input
                      name="lastName"
                      type="text"
                      placeholder="Apellido"
                      value={lastName}
                      onChange={(e) => {
                        e.preventDefault();
                        setLastName(e.target.value);
                      }}
                      className="appearance-none bg-transparent border-none w-full text-white mr-3 leading-tight focus:ring-0 focus:outline-none text-sm"
                      required
                      maxLength={100}
                    />
                  </div>
                </div>
              </div>
              {/* Second row */}
              <div className="w-full flex flex-wrap justify-between">
                {/* Email */}
                <div className="w-full sm:w-60 md:w-1/2 md:pr-2">
                  <div className="w-full border-b-2 border-secondary-500 py-1 px-2 mb-10">
                    <input
                      name="email"
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => {
                        e.preventDefault();
                        setEmail(e.target.value);
                      }}
                      className="appearance-none bg-transparent border-none w-full text-white mr-3 leading-tight focus:ring-0 focus:outline-none text-sm"
                      required
                      maxLength={100}
                    />
                  </div>
                </div>
                {/* Phone */}
                <div className="w-full sm:w-60 md:w-1/2 md:pl-2">
                  <div className="w-full border-b-2 border-secondary-500 py-1 px-2 mb-10">
                    <input
                      name="phone"
                      type="number"
                      placeholder="Teléfono"
                      value={phone}
                      onChange={(e) => {
                        e.preventDefault();
                        setPhone(e.target.value);
                      }}
                      className="appearance-none bg-transparent border-none w-full text-white mr-3 leading-tight focus:ring-0 focus:outline-none text-sm"
                      required
                      maxLength={20}
                    />
                  </div>
                </div>
              </div>
              {/* Third row */}
              <div className="w-full flex flex-wrap justify-between">
                <div className="w-full border-b-2 border-secondary-500 py-1 px-2 mb-10">
                  <input
                    name="subject"
                    type="text"
                    placeholder="Asunto"
                    value={subject}
                    onChange={(e) => {
                      e.preventDefault();
                      setSubject(e.target.value);
                    }}
                    className="appearance-none bg-transparent border-none w-full text-white mr-3 leading-tight focus:ring-0 focus:outline-none text-sm"
                    required
                    maxLength={100}
                  />
                </div>
              </div>
              {/* Fourth row */}
              <div className="w-full flex flex-wrap justify-between">
                <div className="w-full border-2 border-secondary-500 p-2 mb-10">
                  <textarea
                    name="message"
                    placeholder="Mensaje"
                    value={comments}
                    onChange={(e) => {
                      e.preventDefault();
                      setComments(e.target.value);
                    }}
                    className="appearance-none bg-transparent border-none w-full text-white mr-3 leading-tight focus:ring-0 focus:outline-none text-sm resize-none"
                    required
                    maxLength={1000}
                    rows={6}
                  />
                </div>
              </div>
              {/* Send button */}
              <button
                type="submit"
                className="text-primary-500 bg-secondary-500 py-1 px-8 rounded-full hover:bg-secondary-400 bebas uppercase"
                disabled={disabled}
              >
                Enviar
              </button>
            </form>
          </div>
          <div className="w-full md:w-1/2 flex items-center justify-center mt-20 md:-mt-20 mb-12">
            <img
              src="/landing-page/mailbox.png"
              alt="mailbox"
              className="w-9/12 sm:w-7/12 md:w-9/12 lg:w-96"
            />
          </div>
        </div>
      </section>
      <WarningModal
        isOpen={openWarning}
        setOpen={setOpenWarning}
        message={message}
        title={title}
      />
    </>
  );
}
