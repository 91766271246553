// import WatchImg from './img/WatchImg';

const items = [
  {
    title: 'Ni un centavo más',
    text:
      'A través de un sistema de aliados estratégicos, le ofrecemos a nuestros clientes múltiples productos y servicios sin ningún fee de agencia adicional. Es decir, planificar tu evento con Bloop! tendrá el mismo costo que contratar directamente a cada proveedor.',
  },
  {
    title: 'Tu tiempo vale oro',
    text:
      'Facilitamos la solicitud, comparación y aprobación de cotizaciones a través de nuestra cotización digital y agentes de planificación. Ahorra tiempo al delegar en Bloop todas las tareas de tu próxima celebración.',
  },
  {
    title: 'Desconéctate y disfruta',
    text:
      'Supervisamos cada detalle del proceso hasta el día del evento, atendiendo todas tus inquietudes y garantizando el óptimo desempeño de nuestros aliados estratégicos.',
  },
];

export default function AboutUs() {
  return (
    <section className="w-full min-h-screen text-white relative flex flex-col justify-center items-center mb-8">
      <div id="about-us" className="w-full flex justify-center items-center">
        <h1 className="uppercase text-3xl md:text-4xl lg:text-5xl my-4">
          ¿QUÉ ES <span className="text-secondary-500">BLOOPEX</span>?
        </h1>
      </div>
      {/* w-full sm:px-5 flex flex-row justify-center items-center space-x-1 */}
      <div className="flex flex-row flex-wrap w-full">
        <div className="w-full md:w-1/3 lg:w-1/2 p-8 mb-6">
          <img src="/aboutus.png" alt="about us" className="w-11/12" />
        </div>
        <div className="w-full md:w-2/3 lg:w-1/2 space-y-3 justify-items-center justify-center items-center flex flex-col">
          {/* Section title */}

          {/* Content */}
          <p className="leading-snug text-justify">
            Bloopex es una empresa de e-procurement enfocada en facilitar el
            proceso de compras y contratación de servicios de sus clientes.
          </p>
          <p className="leading-snug text-justify">
            A través de herramientas digitales y una amplia gama de aliados
            estratégicos, ofrecemos un servicio único en el que optimizamos el
            proceso de licitación, simplificamos la selección de proveedores y
            facilitamos la gestión de pagos de proyectos.
          </p>
          <p className="leading-snug text-justify">
            Con la ayuda de expertos, nuestros clientes cuentan con la asesoría
            necesaria para entender los elementos básicos de múltiples
            industrias, de manera que logren identificar oportunidades y
            seleccionen a los proveedores más adecuados.
          </p>
          <p className="leading-snug text-justify">
            Adicionalmente, en Bloopex supervisamos la entrega de cada uno de
            los proyectos aprobados, velando por cumplir con los requerimientos
            y garantizar el óptimo estado de los productos solicitados.
          </p>
          <p className="leading-snug text-justify">
            Simplificar el proceso de compras es nuestro propósito. Cuéntanos
            sobre tu próxima solicitud y descubre lo fácil que es optimizar la
            procura de tu organización con Bloopex.
          </p>
          {/* {items.map((item) => (
            <div key={item.title} className="mt-5">
              <p className="text-secondary-500 font-bold">{item.title}</p>
              <p className="leading-snug text-justify">{item.text}</p>
            </div>
          ))} */}
        </div>
      </div>
    </section>
  );
}
