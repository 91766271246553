import React from 'react';
import Nav from './Nav';
import Footer from './Footer';
import Home from './Home';
import AboutUs from './AboutUs';
import HowItWorks from './HowItWorks';
import Services from './serviceSection/Services';
import Contact from './Contact';

export default function Landing() {
  return (
    <div className="bg-primary-600 w-full min-h-screen px-10 pb-10 overflow-x-hidden">
      <Nav />
      <Home />
      <AboutUs />
      <HowItWorks />
      <Contact />
      <Footer />
    </div>
  );
}
